import { createContext, useContext } from "react"
import { ISection } from "data/contentData/interfaces/mongodb/ISection"

export interface IPagingContext {
    paging: IPaging
    showHeader: boolean
    pages: IPagedSection[]
}

interface IPaging {
    prev: string | null
    current: string | null
    next: string | null
    currentPageNumber: number | null
}

interface IPagedSection extends ISection {
    leadText?: any
    url: string
    isCurrent: boolean
}

const PageDataContext = createContext<IPagingContext | null>(null)

export function PageDataProvider({
    paging,
    children
}: {
    paging: IPagingContext | null
    children: JSX.Element | JSX.Element[]
}) {
    return (
        <PageDataContext.Provider value={paging}>
            {children}
        </PageDataContext.Provider>
    )
}

export default function usePageDataProvider() {
    const context = useContext(PageDataContext)

    if (context === undefined)
        throw new Error(
            "usePaging must be used within a PagingProvider"
        )

    return context
}
